import {
  BasicSelect,
  BasicSelectItem,
  FieldContainer,
  List,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  SecondaryField,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';

import { LocalTimezoneAbbreviation } from '../../../../../components/timestamps';
import { FixMeLater } from '../../../../../utils/FixMeLater';
import { getFieldAndErrorProps } from '../../../../../utils/getFieldAndErrorProps';
import { categories, CATEGORY_NONE, ValidIncidentData } from './form_data';

export const FormContent = () => {
  const form = useFormikContext<ValidIncidentData>();
  const tz = LocalTimezoneAbbreviation();

  return (
    <>
      <List>
        <ListItemHeader>
          <ListTitle>Incident details</ListTitle>
        </ListItemHeader>
        <SecondaryField
          label={`Start time (${tz})`}
          InputComponent={TextInput}
          {...getFieldAndErrorProps(form, 'start_time')}
          type="datetime-local"
        />
        <SecondaryField
          label={`End time (${tz})`}
          InputComponent={TextInput}
          {...getFieldAndErrorProps(form, 'end_time')}
          type="datetime-local"
        />
      </List>
      <FieldContainer>
        <PrimaryField
          label="Notes"
          InputComponent={Textarea as FixMeLater}
          {...getFieldAndErrorProps(form, 'notes')}
        />
        <SecondaryField
          label="Category"
          InputComponent={BasicSelect}
          {...getFieldAndErrorProps(form, 'category')}
        >
          <BasicSelectItem value={CATEGORY_NONE}>None</BasicSelectItem>
          {categories.map((c) => (
            <BasicSelectItem key={c} value={c}>
              {capitalize(c)}
            </BasicSelectItem>
          ))}
        </SecondaryField>
      </FieldContainer>
    </>
  );
};
