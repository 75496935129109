import {
  BasicSelect,
  BasicSelectItem,
  CompositeField,
  FieldContainer,
  List,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  SecondaryFieldComposite,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import { useQuery } from 'react-query';

import { getAllProviders, getNetworkInterfaces } from '../../../../../api/controllersApi';
import { FixMeLater } from '../../../../../utils/FixMeLater';
import { getFieldAndErrorProps } from '../../../../../utils/getFieldAndErrorProps';
import { formatISPProduct } from '../../../../../utils/isp_product';
import { formatISPStatus } from '../../../../../utils/isp_status';
import { FirstAndLastAddresses } from './FirstAndLastAddresses';
import { NO_INTERFACE, NO_PROVIDER, ValidPlanData } from './form_data';

export const FormContent = ({ controllerName }: { controllerName: string }) => {
  const form = useFormikContext<ValidPlanData>();

  const providers =
    useQuery(['providers'], getAllProviders, {
      suspense: true,
    }).data ?? [];

  const interfaces =
    useQuery(
      ['controller', controllerName, 'interfaces'],
      () => getNetworkInterfaces(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  return (
    <>
      <List>
        <ListItemHeader>
          <ListTitle>Plan details</ListTitle>
        </ListItemHeader>
        <SecondaryField
          label="Provider"
          InputComponent={BasicSelect}
          {...getFieldAndErrorProps(form, 'provider')}
        >
          <>
            <BasicSelectItem key={NO_PROVIDER} value={NO_PROVIDER} disabled>
              Select a provider
            </BasicSelectItem>
            {providers.map((p) => (
              <BasicSelectItem key={p.sid} value={p.sid}>
                {p.name}
              </BasicSelectItem>
            ))}
          </>
        </SecondaryField>
        <SecondaryField
          label="Product"
          InputComponent={BasicSelect}
          {...getFieldAndErrorProps(form, 'product')}
        >
          {Object.values(api.ISPProduct).map((product) => (
            <BasicSelectItem
              key={product}
              value={product}
              disabled={product === api.ISPProduct.IR_UNKNOWN}
            >
              {formatISPProduct(product)}
            </BasicSelectItem>
          ))}
        </SecondaryField>
        <SecondaryField
          label="Status"
          InputComponent={BasicSelect}
          {...getFieldAndErrorProps(form, 'status')}
        >
          {Object.values(api.ISPStatus).map((status) => (
            <BasicSelectItem
              key={status}
              value={status}
              disabled={status === api.ISPStatus.IS_UNKNOWN}
            >
              {formatISPStatus(status)}
            </BasicSelectItem>
          ))}
        </SecondaryField>
        <SecondaryField
          label="Account Number"
          InputComponent={TextInput}
          {...getFieldAndErrorProps(form, 'account_number')}
        />
        <SecondaryFieldComposite
          label="Bandwidth"
          fields={
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
              <CompositeField
                label="Download"
                InputComponent={TextInput}
                icon="download"
                suffix="Mb/s"
                {...getFieldAndErrorProps(form, 'download_mbps')}
              />
              <CompositeField
                label="Upload"
                InputComponent={TextInput}
                icon="upload"
                suffix="Mb/s"
                {...getFieldAndErrorProps(form, 'upload_mbps')}
              />
            </div>
          }
        />
        <SecondaryField
          label="Price"
          InputComponent={TextInput}
          prefix="USD"
          suffix="monthly"
          {...getFieldAndErrorProps(form, 'monthly_cost_dollars')}
        />
      </List>

      <FieldContainer>
        <PrimaryField
          label="Notes"
          description="Now accepting newlines! Smash that return key."
          InputComponent={Textarea as FixMeLater}
          {...getFieldAndErrorProps(form, 'notes')}
        />
      </FieldContainer>

      <FieldContainer>
        <PrimaryFieldComposite
          label="IP assignment"
          fields={
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
              <div style={{ width: 'fit-content' }}>
                <CompositeField
                  label="IP type"
                  InputComponent={BasicSelect}
                  {...getFieldAndErrorProps(form, 'ip_type')}
                >
                  <BasicSelectItem value="dynamic">Dynamic</BasicSelectItem>
                  <BasicSelectItem value="static">Static</BasicSelectItem>
                </CompositeField>
              </div>
              {form.values.ip_type === 'static' && (
                <div style={{ flex: 1 }}>
                  <CompositeField
                    label="IP range"
                    placeholder="IP range"
                    InputComponent={TextInput}
                    {...getFieldAndErrorProps(form, 'static_ip_range')}
                  />
                </div>
              )}
            </div>
          }
        />
        {form.values.ip_type === 'static' && (
          <>
            <SecondaryField
              label="Gateway address"
              InputComponent={TextInput}
              {...getFieldAndErrorProps(form, 'gateway_addr')}
            />
            <SecondaryField
              label="Controller address"
              InputComponent={TextInput}
              {...getFieldAndErrorProps(form, 'controller_ip')}
            />
            <FirstAndLastAddresses ipRange={form.values.static_ip_range} />{' '}
          </>
        )}
      </FieldContainer>

      <FieldContainer>
        <PrimaryField
          label="Network interface"
          InputComponent={BasicSelect}
          {...getFieldAndErrorProps(form, 'network_interface')}
          defaultValue={NO_INTERFACE}
        >
          <BasicSelectItem value={NO_INTERFACE}>None</BasicSelectItem>
          {interfaces.map((d) => (
            <BasicSelectItem key={d.sid} value={d.sid}>
              {d.interface}
            </BasicSelectItem>
          ))}
        </PrimaryField>
      </FieldContainer>
    </>
  );
};
