import {
  BasicSelect,
  BasicSelectItem,
  Body2,
  CompositeField,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  List,
  ListItem,
  ListItemHeader,
  ListItemLabel,
  ListItemPair,
  ListItemValue,
  ListTitle,
  MinimalCheckboxField,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  space,
  TextInput,
  ToggleInput,
} from '@meterup/metric';
import { produce } from 'immer';
import { orderBy } from 'lodash';
import React from 'react';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { NeutralBadge, PositiveBadge } from '../../../../components/Network/badges';
import { paths } from '../../../../constants';
import { useConfigEditor } from '../../../../context/ConfigEditorContext';
import { ResourceNotFoundError } from '../../../../errors/errors';
import { PSKRotationFrequency, PSKSchemeType } from '../../../../models/MeterV2WirelessServiceSet';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/config/ssids/:eid',
});

export default function SSIDEdit() {
  const { eid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDEdit));

  const configEditor = useConfigEditor();

  const configDraft = configEditor.draftModel;

  const serviceSet = configDraft.getServiceSetByEphemeralId(eid);

  expectDefinedOrThrow(
    serviceSet,
    new ResourceNotFoundError(
      `Service set not found. This could happen if it was recently renamed.`,
    ),
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Service set</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <FieldContainer>
          <PrimaryField
            label="SSID"
            InputComponent={TextInput}
            value={serviceSet.ssid}
            onChange={(nextValue: string) => {
              configEditor.setDraftModel(
                produce((draft) => {
                  const draftServiceSet = draft.getServiceSetByEphemeralId(serviceSet.ephemeralId);

                  if (draftServiceSet) {
                    draftServiceSet.ssid = nextValue;
                  }
                }),
              );
            }}
          />
          <SecondaryField
            label="Broadcast SSID publicly"
            InputComponent={ToggleInput}
            selected={!serviceSet.hidden}
            onChange={(nextValue: boolean) => {
              configEditor.setDraftModel(
                produce((draft) => {
                  const draftServiceSet = draft.getServiceSetByEphemeralId(serviceSet.ephemeralId);

                  if (draftServiceSet) {
                    draftServiceSet.hidden = !nextValue;
                  }
                }),
              );
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <PrimaryFieldComposite
            label="Password"
            fields={
              <HStack spacing={space(8)}>
                <CompositeField
                  label="Type"
                  InputComponent={BasicSelect}
                  value={serviceSet.pskScheme.type}
                  onValueChange={(nextValue: PSKSchemeType) => {
                    configEditor.setDraftModel(
                      produce((draft) => {
                        const draftServiceSet = draft.getServiceSetByEphemeralId(
                          serviceSet.ephemeralId,
                        );

                        if (draftServiceSet) {
                          draftServiceSet.pskScheme.type = nextValue;
                        }
                      }),
                    );
                  }}
                >
                  <BasicSelectItem value={PSKSchemeType.None}>None</BasicSelectItem>
                  <BasicSelectItem value={PSKSchemeType.Static}>Static</BasicSelectItem>
                  <BasicSelectItem value={PSKSchemeType.Rotating}>Rotating</BasicSelectItem>
                  <BasicSelectItem value={PSKSchemeType.MeterAuth} disabled>
                    Meter Auth
                  </BasicSelectItem>
                  <BasicSelectItem value={PSKSchemeType.IEEE8021X} disabled>
                    802.1X
                  </BasicSelectItem>
                </CompositeField>
                {serviceSet.pskScheme.type === 'static' && (
                  <CompositeField
                    label="Password"
                    InputComponent={TextInput}
                    value={serviceSet.pskScheme.staticValue}
                    onChange={(nextValue: string) => {
                      configEditor.setDraftModel(
                        produce((draft) => {
                          const draftServiceSet = draft.getServiceSetByEphemeralId(
                            serviceSet.ephemeralId,
                          );

                          if (draftServiceSet) {
                            draftServiceSet.pskScheme.staticValue = nextValue;
                          }
                        }),
                      );
                    }}
                  />
                )}{' '}
                {serviceSet.pskScheme.type === 'rotating' && (
                  <CompositeField
                    label="Rotation frequency"
                    InputComponent={BasicSelect}
                    value={serviceSet.pskScheme.rotationFrequency}
                    onValueChange={(nextValue: PSKRotationFrequency) => {
                      configEditor.setDraftModel(
                        produce((draft) => {
                          const draftServiceSet = draft.getServiceSetByEphemeralId(
                            serviceSet.ephemeralId,
                          );

                          if (draftServiceSet) {
                            draftServiceSet.pskScheme.rotationFrequency = nextValue;
                          }
                        }),
                      );
                    }}
                  >
                    <BasicSelectItem value={PSKRotationFrequency.Never}>Never</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Daily}>Daily</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Weekly}>Weekly</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Monthly}>Monthly</BasicSelectItem>
                  </CompositeField>
                )}
              </HStack>
            }
          />
        </FieldContainer>
        <FieldContainer>
          <PrimaryFieldComposite
            label="Bands"
            fields={
              <HStack spacing={space(12)}>
                <MinimalCheckboxField
                  label="2.4 GHz"
                  checked={serviceSet.getKnownAndAdditionalBands()['2G']}
                  onChange={(enabled) =>
                    configEditor.setDraftModel(
                      produce((draft) => {
                        const draftServiceSet = draft.getServiceSetByEphemeralId(
                          serviceSet.ephemeralId,
                        );

                        if (draftServiceSet) {
                          draftServiceSet.setBandStatus('2.4 GHz', enabled);
                        }
                      }),
                    )
                  }
                />
                <MinimalCheckboxField
                  label="5 GHz"
                  checked={serviceSet.getKnownAndAdditionalBands()['5G']}
                  onChange={(enabled) =>
                    configEditor.setDraftModel(
                      produce((draft) => {
                        const draftServiceSet = draft.getServiceSetByEphemeralId(
                          serviceSet.ephemeralId,
                        );

                        if (draftServiceSet) {
                          draftServiceSet.setBandStatus('5 GHz', enabled);
                        }
                      }),
                    )
                  }
                />
              </HStack>
            }
          />
          {serviceSet.getKnownAndAdditionalBands().additionalBands.length > 0 && (
            <ListItemPair>
              <ListItemLabel>Additional bands (from JSON)</ListItemLabel>
              <ListItemValue>
                {serviceSet.getKnownAndAdditionalBands().additionalBands.join(', ')}
              </ListItemValue>
            </ListItemPair>
          )}
        </FieldContainer>
        <FieldContainer>
          <PrimaryField
            label="VLAN"
            InputComponent={BasicSelect}
            value={serviceSet.json.network ?? 'none'}
            onValueChange={(nextValue: string) => {
              configEditor.setDraftModel(
                produce((draft) => {
                  const draftServiceSet = draft.getServiceSetByEphemeralId(serviceSet.ephemeralId);

                  if (draftServiceSet) {
                    if (nextValue !== 'none') {
                      draftServiceSet.json.network = nextValue;
                    }
                  }
                }),
              );
            }}
          >
            <BasicSelectItem value="none" disabled>
              None
            </BasicSelectItem>
            {orderBy(
              configDraft.vlans,
              (v) => configDraft.getVLANByName(v.name)?.json['vlan-id'],
            ).map((vlan) => {
              const vlanId = configDraft.getVLANByName(vlan.name)?.json['vlan-id'];
              return (
                <BasicSelectItem
                  key={vlan.name}
                  value={vlan.name}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'center',
                  }}
                >
                  <HStack spacing={space(8)} width="full">
                    {vlan.name}
                    <Body2 style={{ marginLeft: 'auto' }}>{vlanId}</Body2>
                  </HStack>
                </BasicSelectItem>
              );
            })}
          </PrimaryField>
        </FieldContainer>
        <List>
          <ListItemHeader>
            <ListTitle>Access points</ListTitle>
          </ListItemHeader>
          {configDraft.accessPoints.map((ap) => (
            <ListItem key={ap.name}>
              <ListItemLabel>{ap.name}</ListItemLabel>
              <ListItemValue>
                {configDraft.doesAccessPointBroadcastServiceSet(ap, serviceSet) ? (
                  <PositiveBadge icon="wifi" arrangement="hidden-label">
                    Broadcasting
                  </PositiveBadge>
                ) : (
                  <NeutralBadge icon="cross" arrangement="hidden-label">
                    Not broadcasting
                  </NeutralBadge>
                )}
              </ListItemValue>
            </ListItem>
          ))}
        </List>
      </DrawerContent>
    </Drawer>
  );
}
