import { Button } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import {
  fetchServicePlanProviderAndInterfaces,
  PlanProviderAndInterface,
} from '../../../api/controllersApi';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import {
  NetworkInterfaceBadge,
  ServicePlanProductBadge,
  ServicePlanStatusBadge,
} from '../../../components/Network/badges';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { checkDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../utils/isDefined';
import { makeDrawerLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/plans',
});

const columns: Column<PlanProviderAndInterface>[] = [
  {
    Header: 'Status',
    accessor: (p) => p.plan.status,
    Cell: ServicePlanStatusBadge,
  },
  {
    Header: 'Provider',
    accessor: (p) => p.provider?.name,
  },
  {
    Header: 'Product',
    accessor: (p) => p.plan.product,
    Cell: ServicePlanProductBadge,
  },
  {
    Header: 'Interface',
    accessor: (p) => p.interface?.interface,
    Cell: NetworkInterfaceBadge,
  },
];

export default function ControllerServicePlans() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerServicePlans),
  );

  const plans =
    useQuery(
      ['controllers', controllerName, 'service-plans'],
      () => fetchServicePlanProviderAndInterfaces(controllerName),
      { suspense: true },
    ).data ?? [];

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ISPDetails);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <AutoTable
      columns={columns}
      data={plans}
      additionalControls={
        plans.length < 2 && (
          <Button
            as={Link}
            to={makeDrawerLink(paths.drawers.ISPCreate, {
              controllerName,
            })}
            variant="tertiary"
            icon="plusCircle"
            arrangement="leading-icon"
          >
            Create
          </Button>
        )
      }
      onRowDeselect={closeDrawer}
      isRowSelected={(row) => isDefined(drawerParams) && row.plan.sid === drawerParams.planId}
      linkProps={(row) => ({
        to: makeDrawerLink(paths.drawers.ISPDetails, {
          controllerName,
          planId: row.plan.sid,
        }),
      })}
    />
  );
}
