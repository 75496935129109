import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  Textarea,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../../stitches';
import { getFieldAndErrorProps } from '../../../../../utils/getFieldAndErrorProps';
import { InviteUsersFormData, validInviteUsersFormData } from './InviteUsersFormData';

const StyledForm = styled('form', {
  height: '100%',
});

export const EmailsAndRoleForm = ({
  // eslint-disable-next-line react/prop-types
  initialValues,
  onSubmit,
}: {
  initialValues: InviteUsersFormData;
  onSubmit: (values: InviteUsersFormData) => void;
}) => {
  const closeDrawer = useCloseDrawerCallback();
  return (
    <Formik<InviteUsersFormData>
      validationSchema={toFormikValidationSchema(validInviteUsersFormData)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Add users</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <FieldContainer>
                <PrimaryField
                  // TODO: AETM-559
                  InputComponent={Textarea as any}
                  label="Who else should have access?"
                  {...({ placeholder: 'Email(s), comma or whitespace separated' } as any)}
                  {...getFieldAndErrorProps(form, 'emailListRawText')}
                />
              </FieldContainer>
              <FieldContainer>
                <SecondaryField
                  label="Choose a role"
                  InputComponent={BasicSelect}
                  {...getFieldAndErrorProps(form, 'company_role')}
                >
                  <BasicSelectItem value={api.CompanyMembershipRole.guest}>Guest</BasicSelectItem>
                  <BasicSelectItem value={api.CompanyMembershipRole.member}>Member</BasicSelectItem>
                  <BasicSelectItem value={api.CompanyMembershipRole.admin}>Admin</BasicSelectItem>
                </SecondaryField>
              </FieldContainer>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!form.isValid}>
                  Next
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
};
