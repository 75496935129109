import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CompositeField,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  FieldContainer,
  HStack,
  ListItemHeader,
  ListTitle,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
} from '@meterup/metric';
import { mboot } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constants';
import { getFieldAndErrorProps } from '../../../../utils/getFieldAndErrorProps';
import { LegacyNetworkPSKSchemeType } from '../../../../utils/legacy_network_info';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { ValidLegacyNetworkInfoFormData } from './ValidLegacyNetworkInfoFormData';

export default function LegacyNetworkInfoForm({
  controllerName,
  onClickReview,
}: {
  controllerName: string;
  onClickReview: () => void;
}) {
  const form = useFormikContext<ValidLegacyNetworkInfoFormData>();

  const isGuestPasswordDisabled =
    form.values.guest_strategy !== mboot.GuestPasswordStrategy.UNKNOWN;

  return (
    <>
      <DrawerContent>
        <FieldContainer>
          <ListItemHeader>
            <ListTitle>Private network</ListTitle>
          </ListItemHeader>
          <SecondaryField
            label="SSID 5G"
            InputComponent={TextInput}
            {...getFieldAndErrorProps(form, 'private_ssid')}
          />
          <SecondaryField
            label="SSID 2G"
            InputComponent={TextInput}
            {...getFieldAndErrorProps(form, 'private_2g_ssid')}
          />
          <SecondaryField
            label="Password"
            InputComponent={TextInput}
            {...getFieldAndErrorProps(form, 'private_password')}
          />
        </FieldContainer>
        <FieldContainer>
          <ListItemHeader>
            <ListTitle>Guest network</ListTitle>
          </ListItemHeader>
          <SecondaryField
            label="SSID"
            InputComponent={TextInput}
            {...getFieldAndErrorProps(form, 'guest_ssid')}
          />
          <SecondaryFieldComposite
            label="Password"
            fields={
              <HStack spacing={space(8)}>
                <CompositeField
                  label="Type"
                  InputComponent={BasicSelect}
                  {...getFieldAndErrorProps(form, 'guest_psk_scheme')}
                >
                  <BasicSelectItem
                    value={LegacyNetworkPSKSchemeType.Static}
                    disabled={isGuestPasswordDisabled}
                  >
                    Static
                  </BasicSelectItem>
                  <BasicSelectItem value={LegacyNetworkPSKSchemeType.Rotating} disabled>
                    Rotating
                  </BasicSelectItem>
                </CompositeField>
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Static && (
                  <CompositeField
                    label="Password"
                    InputComponent={TextInput}
                    {...getFieldAndErrorProps(form, 'guest_password')}
                  />
                )}{' '}
                {form.values.guest_psk_scheme === LegacyNetworkPSKSchemeType.Rotating && (
                  <CompositeField
                    label="Rotation frequency"
                    InputComponent={BasicSelect}
                    {...getFieldAndErrorProps(form, 'guest_strategy')}
                  >
                    <BasicSelectItem value={mboot.GuestPasswordStrategy.NEVER} disabled>
                      Never
                    </BasicSelectItem>
                    <BasicSelectItem value={mboot.GuestPasswordStrategy.DAILY} disabled>
                      Daily
                    </BasicSelectItem>
                    <BasicSelectItem value={mboot.GuestPasswordStrategy.MONTHLY} disabled>
                      Monthly
                    </BasicSelectItem>
                  </CompositeField>
                )}
              </HStack>
            }
          />
        </FieldContainer>
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            as={Link}
            to={makeDrawerLink(paths.drawers.LegacyNetworkInfo, { controllerName })}
          >
            Cancel
          </Button>
          <Button onClick={onClickReview} disabled={!form.isValid}>
            Review
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
}
