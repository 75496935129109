import { api } from '@meterup/proto';
import * as z from 'zod';

import { convertQuantity } from '../../../../../utils/convertQuantity';
import { usdCents, usdDollars } from '../../../../../utils/currencies';
import {
  LEGACY_kilobitsPerSecond,
  LEGACY_megabitsPerSecond,
} from '../../../../../utils/data_rates';
import { safeParseAddress4, validIPv4Range, validIPv4String } from '../../../../../utils/ipv4';
import { isDefined, isDefinedAndNotEmpty } from '../../../../../utils/isDefined';
import { validISPProduct } from '../../../../../utils/isp_product';
import { validISPStatus } from '../../../../../utils/isp_status';

export const NO_INTERFACE = 'none';
export const NO_PROVIDER = 'none';

export const validPlanData = z
  .object({
    provider: z.string().refine((provider) => provider !== NO_PROVIDER, 'Required'),
    product: validISPProduct.refine((product) => product !== api.ISPProduct.IR_UNKNOWN, 'Required'),
    status: validISPStatus.refine((status) => status !== api.ISPStatus.IS_UNKNOWN, 'Required'),
    account_number: z.string().optional(),
    notes: z.string().optional(),
    download_mbps: z.string().optional(),
    upload_mbps: z.string().optional(),
    monthly_cost_dollars: z
      .string()
      .regex(/^\d+(\.\d{2})?$/, 'Must be a number, optionally with 2 decimal places')
      .optional(),
    ip_type: z.union([z.literal('static'), z.literal('dynamic')]),
    static_ip_range: validIPv4Range().optional(),
    gateway_addr: validIPv4String().optional(),
    controller_ip: validIPv4String().optional(),
    network_interface: z.string().optional(),
  })
  .refine((val) => (val.ip_type === 'static' ? isDefinedAndNotEmpty(val.static_ip_range) : true), {
    message: 'Required',
    path: ['static_ip_range'],
  })
  .refine((val) => (val.ip_type === 'static' ? isDefinedAndNotEmpty(val.gateway_addr) : true), {
    message: 'Required',
    path: ['gateway_addr'],
  })
  .refine((val) => (val.ip_type === 'static' ? isDefinedAndNotEmpty(val.controller_ip) : true), {
    message: 'Required',
    path: ['controller_ip'],
  })
  .refine(
    (val) => {
      if (val.ip_type === 'static') {
        const range = safeParseAddress4(val.static_ip_range);
        const controller = safeParseAddress4(val.controller_ip);
        if (isDefined(range) && isDefined(controller)) {
          return controller.isInSubnet(range);
        }
        return true;
      }
      return true;
    },
    {
      message: 'Must be in the same subnet as the static IP range',
      path: ['controller_ip'],
    },
  );

export type ValidPlanData = z.infer<typeof validPlanData>;

export const fromAPIData = (
  data: api.InternetServicePlan,
  interfaceSid?: string | null,
): ValidPlanData => ({
  provider: data.provider,
  product: data.product,
  status: data.status,
  account_number: data.account_number,
  notes: data.notes,
  download_mbps: convertQuantity(
    data.download_kbps,
    LEGACY_kilobitsPerSecond,
    LEGACY_megabitsPerSecond,
  ).toString(),
  upload_mbps: convertQuantity(
    data.upload_kbps,
    LEGACY_kilobitsPerSecond,
    LEGACY_megabitsPerSecond,
  ).toString(),
  monthly_cost_dollars: convertQuantity(data.monthly_cost_cents, usdCents, usdDollars).toString(),
  ip_type: isDefinedAndNotEmpty(data.static_ip_range) ? 'static' : 'dynamic',
  static_ip_range: data.static_ip_range,
  gateway_addr: data.gateway_addr,
  controller_ip: data.controller_ip,
  network_interface: interfaceSid ?? NO_INTERFACE,
});

export const toISPUpdateRequest = (
  data: ValidPlanData,
): {
  planData: api.ISPUpdateRequest;
  interfaceSid: string | null;
} => ({
  planData: {
    provider: data.provider,
    product: data.product,
    status: data.status,
    account_number: data.account_number ?? '',
    notes: data.notes ?? '',
    download_kbps: convertQuantity(
      Number.parseFloat(data.download_mbps ?? '0'),
      LEGACY_megabitsPerSecond,
      LEGACY_kilobitsPerSecond,
    ),
    upload_kbps: convertQuantity(
      Number.parseFloat(data.upload_mbps ?? '0'),
      LEGACY_megabitsPerSecond,
      LEGACY_kilobitsPerSecond,
    ),
    monthly_cost_cents: convertQuantity(
      Number.parseFloat(data.monthly_cost_dollars ?? '0'),
      usdDollars,
      usdCents,
    ),
    set_dynamic_ip: data.ip_type === 'dynamic',
    static_ip_range: (data.ip_type === 'static' ? data.static_ip_range : '') ?? '',
    gateway_addr: (data.ip_type === 'static' ? data.gateway_addr : '') ?? '',
    controller_ip: (data.ip_type === 'static' ? data.controller_ip : '') ?? '',
    first_usable_ip: '',
    last_usable_ip: '',
  },
  interfaceSid: data.network_interface !== NO_INTERFACE ? data.network_interface ?? null : null,
});

export const toISPCreateRequest = (
  data: ValidPlanData,
): {
  planData: api.ISPCreateRequest;
  interfaceSid: string | null;
} => {
  const apiData = toISPUpdateRequest(data);
  // The only difference between ISPCreateRequest and ISPUpdateRequest is that
  // ISPUpdateRequest has a set_dynamic_ip field. So we remove it. The API won't
  // accept a payload with the field.
  delete (apiData.planData as any).set_dynamic_ip;
  return apiData;
};
