import {
  Button,
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  PrimaryField,
  TextInput,
} from '@meterup/metric';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { createCompanyJSON } from '../../../api/companyApi';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../stitches';
import { getFieldAndErrorProps } from '../../../utils/getFieldAndErrorProps';

const validCompanyFormData = z.object({
  name: z.string(),
});

type ValidCompanyFormData = z.infer<typeof validCompanyFormData>;

const StyledForm = styled('form', {
  display: 'contents',
});

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/new',
});

export default function CreateCompany() {
  const closeDrawer = useCloseDrawerCallback();

  const queryClient = useQueryClient();

  const createCompanyMutation = useMutation(
    async (values: ValidCompanyFormData) => {
      await createCompanyJSON({
        name: values.name,
        slug: ' ',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companies');
        closeDrawer();
      },
    },
  );

  return (
    <Formik<ValidCompanyFormData>
      validationSchema={toFormikValidationSchema(validCompanyFormData)}
      initialValues={{ name: '' }}
      onSubmit={(v) => createCompanyMutation.mutate(v)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Add company</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <FieldContainer>
                <PrimaryField
                  InputComponent={TextInput}
                  label="Company name"
                  {...getFieldAndErrorProps(form, 'name')}
                />
              </FieldContainer>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!form.isValid}
                  loading={createCompanyMutation.isLoading}
                >
                  Save
                </Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
