import { api } from '@meterup/proto';
import { parseISO } from 'date-fns';
import * as z from 'zod';

export const DETAILS = 'details';
export const DURATION = 'duration';
export const CATEGORY_NONE = '';
export const CATEGORY_INSTALLATION = 'installation';
export const CATEGORY_DEINSTALLATION = 'deinstallation';
export const CATEGORY_MAINTENANCE = 'maintenance';
export const CATEGORY_OUTAGE = 'outage';

export const categories = [
  CATEGORY_INSTALLATION,
  CATEGORY_DEINSTALLATION,
  CATEGORY_MAINTENANCE,
  CATEGORY_OUTAGE,
];

export const toIncidentCreateRequest = (
  data: ValidIncidentData,
): {
  incidentData: api.UpsertControllerIncidentRequest;
} => ({
  incidentData: {
    start_time: data.start_time ? parseISO(data.start_time).toISOString() : undefined,
    end_time: data.end_time ? parseISO(data.end_time).toISOString() : undefined,
    notes: data.notes,
    category: data.category,
  },
});

export const toIncidentUpdateDurationRequest = (
  data: ValidIncidentData,
): {
  incidentData: api.UpsertControllerIncidentRequest;
} => ({
  incidentData: {
    start_time: data.start_time ? parseISO(data.start_time).toISOString() : undefined,
    end_time: data.end_time ? parseISO(data.end_time).toISOString() : undefined,
    notes: data.notes,
    category: data.category,
  },
});

export type ValidIncidentData = z.infer<typeof validIncidentData>;

export const validIncidentData = z.object({
  start_time: z.string().optional(),
  end_time: z.string().optional(),
  notes: z.string().optional(),
  category: z.string().optional(),
});
