import { FormikProps } from 'formik';
import React from 'react';

import { isDefined } from './isDefined';

const getFieldProps = <T extends object>(form: FormikProps<T>, key: keyof T & string) => ({
  ...form.getFieldProps(key),
  onChange: (eventOrValue: React.ChangeEvent | any) => {
    if (typeof eventOrValue === 'object') {
      form.handleChange(eventOrValue);
    } else {
      form.setFieldValue(key, eventOrValue);
    }
  },
  onValueChange: (value: string) => form.setFieldValue(key, value),
});

const getErrorProps = <T extends object>(form: FormikProps<T>, key: keyof T) => ({
  errorMessage: form.touched[key] ? form.errors[key] : undefined,
  hasError: form.touched[key] && isDefined(form.errors[key]),
});

export const getFieldAndErrorProps = <T extends object>(
  form: FormikProps<T>,
  key: keyof T & string,
) => ({
  ...getFieldProps(form, key),
  ...getErrorProps(form, key),
});
